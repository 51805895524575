import React from 'react';
import * as icons from '@material-ui/icons';
import { store } from '~/store';

export default function Categorys() {
  const { type } = store.getState().user.profile;

  if (type === 'client') {
    return [
      {
        title: 'Menu',
        links: [
          {
            icon: <icons.Business />,
            text: 'Dados da empresa',
            route: '/empresa/info',
            dev: false,
          },
          {
            icon: <icons.Group />,
            text: 'Funcionários',
            route: '/empresa/funcionarios',
            dev: false,
          },
        ],
      },
      {
        title: 'Pagamento',
        links: [
          {
            icon: <icons.Payment />,
            text: 'Formas de pagamento',
            route: '/empresa/pagamento',
            dev: true,
          },
          {
            icon: <icons.Receipt />,
            text: 'Extrato da conta',
            route: '/empresa/extrato',
            dev: true,
          },
        ],
      },
      {
        title: 'Armazenamento',
        links: [
          {
            icon: <icons.Storage />,
            text: 'Gerenciar espaço',
            route: '/empresa/armazenamento',
            dev: true,
          },
        ],
      },
      {
        title: 'Preferencias de Conta',
        links: [
          {
            icon: <icons.Description />,
            text: 'Alteração de plano',
            route: '/empresa/planos',
            dev: true,
          },
          {
            icon: <icons.WorkOff />,
            text: 'Cancelamento de conta',
            route: '/empresa/cancelamento',
            dev: true,
          },
        ],
      },
    ];
  }
  return [
    {
      title: 'Menu',
      links: [
        {
          icon: <icons.Person />,
          text: 'Clientes',
          route: '/clientes',
        },
        {
          icon: <icons.PeopleAlt />,
          text: 'Leads',
          route: '/leads',
        },
      ],
    },
  ];
}
