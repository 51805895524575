/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Space,
  Tooltip,
  Table,
  Select,
  Form,
  Input,
  Popconfirm,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  CheckOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import * as styles from './styles';
import api from '~/services/api';

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    // eslint-disable-next-line no-nested-ternary
    dataIndex === 'status' ? (
      <Select defaultValue={record.status}>
        <Option value="new">Novo Lead</Option>
        <Option value="ser">Tem interesse</Option>
      </Select>
    ) : dataIndex === 'contact' ? (
      <Select defaultValue={record.contact}>
        <Option value>Sim</Option>
        <Option value={false}>Não</Option>
      </Select>
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Por Favor coloque o ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default function EditableTable({ title }) {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const [loading, setLoading] = useState(true);

  const [resData, setResData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    async function refreshData() {
      try {
        const response = await api.get(`/leads`);
        const { data } = response;
        setResData(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    refreshData();
    setRefresh(false);
  }, [refresh]);

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      email: '',
      cnpj: '',
      phone: '',
      status: '',
      branch: '',
      contact: '',
      ...record,
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (record, rowIndex) => {
    try {
      const row = await form.validateFields();
      const newData = [...resData];
      const index = rowIndex;

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        await api.put(`leads/${record._id}`, row);
        setResData(newData);
        setRefresh(true);
        setEditingKey('');
        toast.success('Lead atualizado com sucesso!');
      } else {
        newData.push(row);
        setResData(newData);
        setRefresh(true);
        setEditingKey('');
      }
    } catch (error) {
      if (error.response.data.error === 'Validation fails') {
        return toast.error(`Não foi possível atualizar o lead.\n
    Erro de validação.`);
      }
      if (
        error.response.data.error ===
        'You do not have permission to update leads'
      ) {
        return toast.error(`Não foi possível atualizar o lead.\n
    Você não tem permissão para atualizar leads.`);
      }
      if (error.response.data.error === 'Lead does not found') {
        return toast.error(`Não foi possível atualizar o lead.\n
    Lead não encontrado.`);
      }
      if (error.response.data.error === 'id does not have 24 characters') {
        return toast.error(`Não foi possível atualizar o lead.\n
    Id não contém 24 caracteres.`);
      }
      return toast.error('Não foi possível atualizar o lead.');
    }
  };

  async function handleDelete(record, rowIndex) {
    try {
      await api.delete(`leads/${record._id}`);
      const dataDelete = [...resData];
      dataDelete.splice(rowIndex, 1);
      setResData([...dataDelete]);
      toast.success('Lead deletado com sucesso!');
    } catch (error) {
      if (
        error.response.data.error ===
        'You do not have permission to update leads'
      ) {
        return toast.error(`Não foi possível atualizar o lead.\n
    Você não tem permissão para atualizar leads.`);
      }
      if (error.response.data.error === 'Lead does not found') {
        return toast.error(`Não foi possível atualizar o lead.\n
    Lead não encontrado.`);
      }
      if (error.response.data.error === 'id does not have 24 characters') {
        return toast.error(`Não foi possível atualizar o lead.\n
    Id não contém 24 caracteres.`);
      }
      return toast.error('Não foi possível deletar o lead.');
    }
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      editable: true,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      editable: true,
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      editable: true,
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      editable: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      editable: true,
      render: (status) => `${status === 'new' ? 'Novo Lead' : 'Tem interesse'}`,
    },
    {
      title: 'Ramo',
      dataIndex: 'branch',
      editable: true,
    },
    {
      title: 'Houve Contato?',
      dataIndex: 'contact',
      editable: true,
      render: (contact) => `${contact === true ? 'Sim' : 'Não'}`,
    },
    {
      title: 'Ações',
      render: (_, record, rowIndex) => {
        const editable = isEditing(record);
        return (
          <Space size="middle">
            {editable ? (
              <>
                <Popconfirm
                  title="Efetuar alterações?"
                  onConfirm={() => save(record, rowIndex)}
                >
                  <Tooltip title="Salvar">
                    <Button type="" shape="circle" icon={<CheckOutlined />} />
                  </Tooltip>
                </Popconfirm>
                <Tooltip title="Voltar">
                  <Button
                    type=""
                    shape="circle"
                    onClick={cancel}
                    icon={<UndoOutlined />}
                  />
                </Tooltip>
              </>
            ) : (
              <Tooltip title="Editar">
                <Button
                  type=""
                  shape="circle"
                  disabled={editingKey !== ''}
                  onClick={() => edit(record)}
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )}
            <Popconfirm
              title="Deseja mesmo Deletar?"
              onConfirm={() => handleDelete(record, rowIndex)}
            >
              <Tooltip title="Deletar">
                <Button
                  type=""
                  shape="circle"
                  disabled={editingKey !== ''}
                  icon={<DeleteOutlined />}
                  danger
                />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const orderedColumns = columns.filter((col) => col.dataIndex !== '_id');

  const mergedColumns = orderedColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === 'status' || col.dataIndex === 'contact'
            ? 'select'
            : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <styles.Container>
      <Form form={form} component={false}>
        <Table
          title={() => title}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          dataSource={resData}
          loading={loading}
          rowKey="_id"
          pagination={{
            total: resData.length,
            pageSize: resData.length,
            hideOnSinglePage: true,
          }}
        />
      </Form>
    </styles.Container>
  );
}

EditableTable.propTypes = {
  title: PropTypes.string.isRequired,
};

EditableCell.defaultProps = {
  editing: false,
  dataIndex: '',
  title: '',
  inputType: '',
  record: {
    name: '',
    email: '',
    cnpj: '',
    phone: '',
    status: '',
    branch: '',
    contact: '',
  },
  index: [],
  children: [],
};

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  inputType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  record: PropTypes.object,
  index: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.any,
};
