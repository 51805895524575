/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  Input,
  InputNumber,
  Button,
  Radio,
  Card,
  Popconfirm,
} from 'antd';
import cep from 'cep-promise';
import { toast } from 'react-toastify';
import { InfoCircleOutlined } from '@ant-design/icons';
import MaskedInput from 'antd-mask-input';

import api from '~/services/api';

function Address({ data, handleChange }) {
  const address = data;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const validateMessages = {
    types: {
      number: 'Insira um número válido!',
    },
  };

  async function searchCep(cepInfo) {
    try {
      setLoading(true);
      const response = await cep(cepInfo);
      setLoading(false);
      form.setFieldsValue({
        state: response.state,
        city: response.city,
        neighborhood: response.neighborhood,
        street: response.street,
      });
    } catch (error) {
      toast.error('CEP não encontrado.');
      setLoading(false);
    }
  }

  async function onFinish(values) {
    await api
      .put(`/address/${address.id}`, {
        billing_address: !(
          values.billing_address === undefined ||
          values.billing_address === false
        ),
        zip_code: values.zip_code,
        state: values.state,
        city: values.city,
        neighborhood: values.neighborhood,
        street: values.street,
        number_street: values.number_street,
      })
      .then(() => {
        handleChange();
        toast.success('Endereço atualizado com sucesso!');
      })
      .catch(() => {
        toast.error(
          'Não foi possível atualizar o endereço! Verifique se os campos foram preenchidos corretamente.'
        );
      });
  }

  async function deleteAddress() {
    await api
      .delete(`/address/${address.id}`)
      .then(() => {
        handleChange();
        toast.success('Endereço excluido com sucesso!');
      })
      .catch((error) => {
        if (
          error.response.data.error ===
          'You do not have permission to update this address'
        ) {
          return toast.error(`Não foi possível excluir o endereço!\n
          Você não tem permissão para atualizar este endereço.`);
        }
        if (error.response.data.error === 'Address does not found') {
          return toast.error(`Não foi possível excluir o endereço!\n
          Endereço não encontrado.`);
        }
        return toast.error(`Não foi possível excluir o endereço!`);
      });
  }

  return (
    <Card style={{ marginTop: '10px' }}>
      <Form
        form={form}
        layout="vertical"
        initialValues={address}
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item
          label="Endereço de:"
          name="billing_address"
          tooltip={{
            title: 'Defina a forma de contato',
            icon: <InfoCircleOutlined />,
          }}
          style={{
            display: 'inline-block',
            width: '20%',
          }}
        >
          <Radio.Group>
            <Radio.Button value>Cobrança</Radio.Button>
            <Radio.Button value={false}>Contato</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="CEP"
          name="zip_code"
          style={{
            display: 'inline-block',
            width: 'calc(25% - 8px)',
            marginLeft: '8px',
          }}
        >
          <MaskedInput
            onBlur={(e) => searchCep(e.target.value)}
            mask="11111-111"
            placeholder="Ex.: 12000-000"
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: '0' }}>
          <Form.Item
            label="Estado"
            name="state"
            style={{
              display: 'inline-block',
              width: '15%',
            }}
          >
            <Input disabled={loading} placeholder="Ex.: SP" />
          </Form.Item>

          <Form.Item
            label="Cidade:"
            name="city"
            style={{
              display: 'inline-block',
              width: 'calc(15% - 8px)',
              marginLeft: '8px',
            }}
          >
            <Input disabled={loading} placeholder="Ex.: São José dos Campos" />
          </Form.Item>
          <Form.Item
            label="Bairro:"
            name="neighborhood"
            style={{
              display: 'inline-block',
              width: 'calc(15% - 8px)',
              marginLeft: '8px',
            }}
          >
            <Input disabled={loading} placeholder="Ex.: Vila Tatetuba" />
          </Form.Item>

          <Form.Item
            name="street"
            label="Rua:"
            style={{
              display: 'inline-block',
              width: 'calc(45% - 8px)',
              marginLeft: '8px',
            }}
          >
            <Input
              disabled={loading}
              placeholder="Ex.: Rua Dona Lúcia Telles"
            />
          </Form.Item>

          <Form.Item
            name="number_street"
            label="Número:"
            style={{
              display: 'inline-block',
              width: 'calc(10% - 8px)',
              marginLeft: '8px',
            }}
          >
            <InputNumber
              type="number"
              style={{ width: '100%' }}
              placeholder="Ex.: 107"
            />
          </Form.Item>
        </Form.Item>

        <Form.Item style={{ marginBottom: '0px' }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: '20px' }}
          >
            Salvar
          </Button>
          <Popconfirm
            title="Tem certeza que deseja excluir?"
            onConfirm={deleteAddress}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="primary" danger htmlType="submit">
              Excluir
            </Button>
          </Popconfirm>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default Address;

Address.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
};
