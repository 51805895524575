import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;

  form {
    hr {
      width: 100%;
      border: 0px;
      height: 1px;
      background: rgba(0, 0, 0, 0.2);
      margin: 20px 0 15px;
    }
  }
`;
