/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  Radio,
  Card,
  Upload,
} from 'antd';
import {
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import MaskedInput from 'antd-mask-input';
import { toast } from 'react-toastify';
import api from '~/services/api';
import Slug from './components/Slug';

function Client({ isClient, data, handleChange }) {
  const client = data;
  const [isJuridic, setIsJuridic] = useState(client.is_juridic);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');

  const [form] = Form.useForm();
  const token = useSelector((state) => state.auth.token);
  const validateMessages = {
    types: {
      email: 'Insira um email válido!',
      number: 'Insira um número válido!',
    },
  };

  function handleChangeAvatar(info) {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setImage(info.file.response);
      setLoading(false);
    }
  }

  async function onFinish(formData) {
    formData.file_id = image.id;
    const response = await api
      .put(`/companies/${client.id}`, formData)
      .then(
        handleChange(),
        toast.success('Informações da empresa atualizadas!')
      )
      .catch((error) => {
        if (
          error.response.data.error ===
          'You do not have permission to update this company'
        ) {
          return toast.error(`Não foi possível atualizar os dados.\n
          Você não tem permissão para atualizar esta empresa.`);
        }
        if (error.response.data.error === 'Company was not found') {
          return toast.error(`Não foi possível atualizar os dados.\n
          Empresa não encontrada.`);
        }
        if (
          error.response.data.error ===
          '"Contact email alredy in use", "Charge email alredy in use", "Document alredy in use", "IE alredy in use", "IM alredy in use"'
        ) {
          return toast.error(`Não foi possível atualizar os dados.\n
          Dados sendo utilizados em outra empresa.`);
        }
        return toast.error('Não foi possível atualizar os dados.');
      });

    if (response) {
      toast.success('Atualizado com sucesso!');
    }
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      toast.error('Você só pode fazer upload de arquivos JPG/PNG!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error('O arquivo deve ser menor que 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  function handleChangeIsJuridic(value) {
    setIsJuridic(value);
  }
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Slug client={client} handleChange={handleChange} />
      <Form
        form={form}
        layout="vertical"
        initialValues={client}
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        {isClient ? (
          ''
        ) : (
          <Card
            title="Status da Aplicação"
            style={{ width: '100%' }}
            bordered={false}
          >
            <Form.Item
              label="Status:"
              name="status"
              style={{
                display: 'inline-block',
                width: '50%',
              }}
            >
              <Select>
                <Select.Option value="Instanciar">Instanciar</Select.Option>
                <Select.Option value="Ativado">Ativado</Select.Option>
                <Select.Option value="Desativado">Desativado</Select.Option>
              </Select>
            </Form.Item>
          </Card>
        )}

        <Card
          title="Informações da Empresa"
          style={{ width: '100%' }}
          bordered={false}
        >
          <Form.Item style={{ marginBottom: '0' }}>
            <Form.Item
              label="Nome:"
              name="name"
              style={{
                display: 'inline-block',
                width: '50%',
              }}
            >
              <Input placeholder="Nome completo da empresa" />
            </Form.Item>
            <Form.Item
              label="Nome Fantasia / Marca:"
              name="fantasy_name"
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                marginLeft: '8px',
              }}
            >
              <Input />
            </Form.Item>
          </Form.Item>

          <Form.Item style={{ marginBottom: '0' }}>
            <Form.Item
              label="Tipo de pessoa "
              name="is_juridic"
              style={{
                display: 'inline-block',
                width: '25%',
              }}
            >
              <Select defaultValue onChange={handleChangeIsJuridic}>
                <Select.Option value>Pessoa Juridica</Select.Option>
                <Select.Option>Pessoa Física</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={isJuridic ? 'CNPJ' : 'CPF'}
              name="document"
              style={{
                display: 'inline-block',
                width: 'calc(25% - 8px)',
                marginLeft: '8px',
              }}
            >
              {isJuridic ? (
                <MaskedInput mask="11.111.111/1111-11" />
              ) : (
                <MaskedInput mask="111.111.11-11" />
              )}
            </Form.Item>

            {isJuridic ? (
              <>
                <Form.Item
                  label="Inscrição Estadual"
                  name="ie"
                  tooltip={{
                    title: 'Inscrição Estadual do estabelecimento',
                    icon: <InfoCircleOutlined />,
                  }}
                  style={{
                    display: 'inline-block',
                    width: 'calc(25% - 8px)',
                    marginLeft: '8px',
                  }}
                >
                  <InputNumber type="number" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  label="IE Isento"
                  name="ie_free"
                  style={{
                    display: 'inline-block',
                    width: 'calc(25% - 8px)',
                    marginLeft: '8px',
                  }}
                >
                  <Radio.Group>
                    <Radio.Button value="true">Sim</Radio.Button>
                    <Radio.Button value="false">Não</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </>
            ) : (
              ''
            )}
          </Form.Item>

          {isJuridic ? (
            <Form.Item style={{ marginBottom: '0' }}>
              <Form.Item
                label="Inscrição Municipal"
                name="im"
                style={{
                  display: 'inline-block',
                  width: '25%',
                }}
              >
                <InputNumber type="number" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                label="CNAE"
                name="cnae"
                style={{
                  display: 'inline-block',
                  width: 'calc(25% - 8px)',
                  marginLeft: '8px',
                }}
              >
                <InputNumber type="number" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                label="Código de regime tributário"
                name="cod_regime"
                style={{
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                  marginLeft: '8px',
                }}
              >
                <Select defaultValue="Regime Normal">
                  <Select.Option value="Regime Normal">
                    Regime Normal
                  </Select.Option>
                  <Select.Option value="Pessoa Nacional">
                    Pessoa Nacional
                  </Select.Option>
                  <Select.Option value="Regime Nacional - Excesso de sublimite de receita bruta">
                    Regime Nacional - Excesso de sublimite de receita bruta
                  </Select.Option>
                </Select>
              </Form.Item>
            </Form.Item>
          ) : (
            ''
          )}
        </Card>

        <Card title="Logo" style={{ width: '100%' }} bordered={false}>
          <Form.Item name="logo">
            <Upload
              name="file"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="https://api.portal.biplan.com.br/files"
              headers={{
                authorization: `Bearer ${token}`,
              }}
              beforeUpload={beforeUpload}
              onChange={handleChangeAvatar}
            >
              {image.url ? (
                <img src={image.url} alt="avatar" style={{ width: '150%' }} />
              ) : client.logo ? (
                <img
                  src={client.logo.url}
                  alt="avatar"
                  style={{ width: '150%' }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Card>

        <Card title="Contato" style={{ width: '100%' }} bordered={false}>
          <Form.Item style={{ marginBottom: '0' }}>
            <Form.Item
              label="Pessoa de contato"
              name="contact_name"
              style={{
                display: 'inline-block',
                width: '50% ',
              }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Fone"
              name="contact_phone"
              style={{
                display: 'inline-block',
                width: 'calc(25% - 8px)',
                marginLeft: '8px',
              }}
            >
              <MaskedInput mask="(111) 1111-1111" />
            </Form.Item>
            <Form.Item
              label="Celular"
              name="cell_phone"
              style={{
                display: 'inline-block',
                width: 'calc(25% - 8px)',
                marginLeft: '8px',
              }}
            >
              <MaskedInput mask="(111) 11111-1111" />
            </Form.Item>
          </Form.Item>

          <Form.Item style={{ marginBottom: '0' }}>
            <Form.Item
              label="Email"
              name="contact_email"
              rules={[{ type: 'email' }]}
              style={{
                display: 'inline-block',
                width: '33% ',
              }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email de cobrança"
              name="charge_email"
              rules={[{ type: 'email' }]}
              style={{
                display: 'inline-block',
                width: 'calc(33% - 8px)',
                marginLeft: '8px',
              }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Site"
              name="site"
              style={{
                display: 'inline-block',
                width: 'calc(33% - 8px)',
                marginLeft: '8px',
              }}
            >
              <Input />
            </Form.Item>
          </Form.Item>
        </Card>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default Client;

Client.defaultProps = {
  isClient: false,
};

Client.propTypes = {
  data: PropTypes.element.isRequired,
  handleChange: PropTypes.func.isRequired,
  isClient: PropTypes.bool,
};
