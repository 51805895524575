import React from 'react';

import Table from './AntdTable/index';

import * as styles from './styles';

function Leads() {
  return (
    <styles.Container>
      <Table title="Leads" />
    </styles.Container>
  );
}

export default Leads;
