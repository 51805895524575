import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 415px;
  text-align: center;
  background: #455862;
  border-radius: 4px;

  img {
    width: 230px;
    height: 200px;
    padding-top: 65px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    align-items: center;

    input {
      background: rgba(0, 0, 0, 0.1);
      width: 75%;
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #fff;
      margin: 0 0 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transition: 0.2s;

      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }

      &:focus {
        box-shadow: 0 0 5px #78b02d;
        border: 1px solid #78b02d;

        &::placeholder {
          color: #78b02d;
        }
      }
    }

    span {
      color: #b70d0e;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }

    button {
      margin: 25px 0 25px;
      height: 44px;
      width: 75%;
      background: #78b02d;
      color: #fff;
      border: 0;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, '#78B02D')};
      }
    }
  }
`;
