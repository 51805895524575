import React from 'react';
// import { Formik, Form, Field } from 'formik';
// import { TextField } from 'formik-material-ui';
// import { Button } from '@material-ui/core';
import { Card } from 'antd';
import ProfileInfo from './components/ProfileInfo';

import { Container } from './styles';

export default function Profile() {
  return (
    <Container>
      <Card style={{ width: '100%' }} bordered={false}>
        <ProfileInfo />
      </Card>
    </Container>
  );
}
