/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import { Card, Collapse } from 'antd';
import Client from '~/components/Client';
import Address from '~/components/Address';
import NewAddress from '~/components/NewAddress';
import DigitalCertificate from '~/components/DigitalCertificate';
import IncomingInfo from './components/IncomingInfo';
import AddIncomingInfo from './components/AddIncomingInfo';
import OutgoingInfo from './components/OutgoingInfo';
import AddOutgoingInfo from './components/AddOutgoingInfo';

import StatusAlert from '~/components/StatusAlert';

import { Container } from './styles';

import api from '~/services/api';

const { Panel } = Collapse;

export default function InfoCompany({ responseCompanyId }) {
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState([true]);
  const [listAddress, setListAddress] = useState([]);

  const [refresh, setRefresh] = useState(false);

  const [certificate, setCertificate] = useState(null);
  const [loadingCertificate, setLoadingCertificate] = useState(true);

  const [mail, setMail] = useState([]);

  const handleEditCompanyInfo = useCallback(() => {
    setRefresh(true);
  }, []);

  useEffect(() => {
    async function loadCertificate(companyId) {
      await api
        .get(`/digitalcertificates/${companyId}`)
        .then((response) => {
          setCertificate(response.data);
        })
        .catch(() => {
          setLoadingCertificate(false);
        });

      setLoadingCertificate(false);
    }

    async function loadCompany() {
      const response = await api.get(`/companies/${responseCompanyId}`);
      const { data } = response;
      setCompany(data);
      setLoading(false);

      loadCertificate(data.id);
    }

    loadCompany();
    setRefresh(false);
  }, [responseCompanyId, refresh]);

  useEffect(() => {
    async function loadAddress() {
      const response = await api.get(`/address`);
      const { data } = response;
      setListAddress(data);
    }

    loadAddress();
    setRefresh(false);
  }, [responseCompanyId, refresh]);

  useEffect(() => {
    async function loadMailData() {
      const response = await api.get(`/emailserver/${responseCompanyId}`);
      const { data } = response;
      setMail(
        data.sort((a, b) => {
          if (a.id > b.id) {
            return 1;
          }
          return -1;
        })
      );
    }

    loadMailData();
  }, [responseCompanyId, refresh]);

  return (
    <Container>
      {!company ? '' : <StatusAlert data={company} />}

      <Collapse
        defaultActiveKey={['CompanyData']}
        style={{ width: '100%' }}
        accordion
      >
        <Panel header="Dados da Empresa" key="CompanyData">
          {!loading ? (
            <Client
              isClient
              handleChange={handleEditCompanyInfo}
              data={company}
            />
          ) : (
            ''
          )}
        </Panel>

        <Panel header="Endereços cadastrados" key="ClientAddress">
          {listAddress.map((address) => (
            <Address
              key={address.id}
              data={address}
              handleChange={handleEditCompanyInfo}
            />
          ))}
          <NewAddress handleChange={handleEditCompanyInfo} data={company.id} />
        </Panel>

        <Panel header="Certificado Digital" key="DigitalCertificate">
          <Card
            title="Certificado Digital"
            style={{ width: '100%' }}
            bordered={false}
            loading={loadingCertificate}
          >
            {!loadingCertificate ? (
              <DigitalCertificate company={company} data={certificate} />
            ) : (
              ''
            )}
          </Card>
        </Panel>

        <Panel header="E-Mail" key="Mail">
          <Card
            title="E-Mail"
            style={{ width: '100%' }}
            bordered={false}
            loading={loadingCertificate}
          >
            <AddIncomingInfo
              data={mail}
              id={responseCompanyId}
              handleChange={handleEditCompanyInfo}
            />
            <AddOutgoingInfo
              data={mail}
              id={responseCompanyId}
              handleChange={handleEditCompanyInfo}
            />
            {mail.map((mailInfo) =>
              mailInfo.server_type === 'SMTP' ? (
                <OutgoingInfo
                  key={mailInfo.id}
                  handleChange={handleEditCompanyInfo}
                  id={responseCompanyId}
                  data={mailInfo}
                />
              ) : (
                <IncomingInfo
                  key={mailInfo.id}
                  handleChange={handleEditCompanyInfo}
                  id={responseCompanyId}
                  data={mailInfo}
                />
              )
            )}
          </Card>
        </Panel>
      </Collapse>
    </Container>
  );
}

InfoCompany.propTypes = {
  responseCompanyId: PropTypes.number.isRequired,
};
