/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Button,
  Drawer,
  Tooltip,
  Space,
  Switch,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  CheckOutlined,
  UndoOutlined,
  UnlockOutlined,
} from '@ant-design/icons';

import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import StatusAlert from '~/components/StatusAlert';
import api from '~/services/api';

import NewEmployee from './components/NewEmployee';

function EditableCell({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Preencha o ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}

function EmployeesData({ clientInfo }) {
  const [resData, setResData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const handleAddEmployee = useCallback(() => {
    setRefresh(true);
  }, []);

  useEffect(() => {
    async function loadEmployees() {
      try {
        const response = await api.get(`/employees/${clientInfo}`);
        const { data } = response;
        setResData(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadEmployees();
    setRefresh(false);
  }, [clientInfo, refresh]);

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      email: '',
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (record, rowIndex) => {
    try {
      const row = await form.validateFields();
      const newData = [...resData];
      const index = rowIndex;

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        await api.put(`employees/${record.id}`, row);
        setResData(newData);
        setRefresh(true);
        setEditingKey('');
        toast.success('Funcionário atualizado com sucesso!');
      } else {
        newData.push(row);
        setResData(newData);
        setRefresh(true);
        setEditingKey('');
      }
    } catch (error) {
      if (error.response.data.error === 'Validation fails') {
        return toast.error(`Não foi possível atualizar.\n
    Erro na validação.`);
      }
      if (
        error.response.data.error ===
        'You do not have permission to update an employee'
      ) {
        return toast.error(`Não foi possível atualizar.\n
    Você não tem permissão para atualizar o funcionário.`);
      }
      if (error.response.data.error === 'Employee does not found') {
        return toast.error(`Não foi possível atualizar.\n
    Funcionário não encontrado.`);
      }
      if (error.response.data.error === 'Email already in use') {
        return toast.error(`Não foi possível atualizar.\n
    Email em uso.`);
      }
      return toast.error('Não foi possível atualizar.');
    }
  };

  async function handleDelete(record, rowIndex) {
    try {
      await api.delete(`employees/${record.id}`);
      const dataDelete = [...resData];
      dataDelete.splice(rowIndex, 1);
      setResData([...dataDelete]);
      toast.success('Funcionário deletado com sucesso!');
    } catch (error) {
      if (error.response.data.error === 'Employee does not found') {
        return toast.error(`Não foi possível deletar.\n
    Funcionário não encontrado.`);
      }
      if (
        error.response.data.error ===
        'You do not have permission to delete an employee'
      ) {
        return toast.error(`Não foi possível atualizar.\n
    Você não tem permissão para deletar um funcionário.`);
      }
      return toast.error('Não foi possível deletar.');
    }
  }

  const [visible, setVisible] = useState(false);
  const [employeeId, setEmployeeId] = useState();

  const [permissionInfo, setPermissionInfo] = useState([]);

  async function handlePermissionChange(data, permissionData) {
    try {
      await api.put(`/permission/${permissionData.id}`, {
        name: permissionData.name,
        allow_access: data,
        employee_id: employeeId,
      });
      toast.success('Nível de permissão atualizado com sucesso!');
    } catch (error) {
      toast.error('Erro ao atualizar Nível de Permissão');
    }
  }

  async function showDrawer(record) {
    try {
      const response = await api.get(`/permission/${record.id}`);
      const { data } = response;
      setPermissionInfo(data);
      setVisible(true);
      setEmployeeId(record.id);
    } catch (error) {
      toast.error('Erro ao carregar as permissões');
    }
  }

  function onClose() {
    setVisible(false);
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      width: '50%',
      editable: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '50%',
      editable: true,
    },
    {
      title: 'Ações',
      dataIndex: 'operation',
      render: (_, record, rowIndex) => {
        const editable = isEditing(record);
        return (
          <Space size="middle">
            <Tooltip title="Níveis de permissão">
              <Button
                type=""
                shape="circle"
                onClick={() => showDrawer(record)}
                icon={<UnlockOutlined />}
              />
            </Tooltip>
            {editable ? (
              <>
                <Popconfirm
                  title="Efetuar alterações?"
                  onConfirm={() => save(record, rowIndex)}
                >
                  <Tooltip title="Salvar">
                    <Button type="" shape="circle" icon={<CheckOutlined />} />
                  </Tooltip>
                </Popconfirm>
                <Tooltip title="Voltar">
                  <Button
                    type=""
                    shape="circle"
                    onClick={cancel}
                    icon={<UndoOutlined />}
                  />
                </Tooltip>
              </>
            ) : (
              <Tooltip title="Editar">
                <Button
                  type=""
                  shape="circle"
                  disabled={editingKey !== ''}
                  onClick={() => edit(record)}
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )}
            {rowIndex === 0 ? (
              ''
            ) : (
              <Popconfirm
                title="Deseja mesmo Deletar?"
                onConfirm={() => handleDelete(record, rowIndex)}
              >
                <Tooltip title="Deletar">
                  <Button
                    type=""
                    shape="circle"
                    disabled={editingKey !== ''}
                    icon={<DeleteOutlined />}
                    danger
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const orderedColumns = columns.filter((col) => col.dataIndex !== 'id');

  const mergedColumns = orderedColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <>
      {!clientInfo ? '' : <StatusAlert data={clientInfo} />}
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          loading={loading}
          dataSource={resData}
          columns={mergedColumns}
          rowKey="_id"
          rowClassName="editable-row"
          pagination={{
            total: resData.length,
            pageSize: resData.length,
            hideOnSinglePage: true,
          }}
        />
        <Drawer
          title="Níveis de permissão"
          width={720}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form form={form} layout="vertical">
            {permissionInfo.map((permission) => (
              <Form.Item
                label={permission.name}
                name="name"
                style={{
                  display: 'inline-block',
                  width: '50%',
                }}
              >
                <Switch
                  checkedChildren="Com Permissão"
                  unCheckedChildren="Sem Permissão"
                  defaultChecked={permission.allow_access}
                  onChange={(value) =>
                    handlePermissionChange(value, permission)
                  }
                />
              </Form.Item>
            ))}
          </Form>
        </Drawer>
      </Form>
      <NewEmployee id={clientInfo} AddEmployee={handleAddEmployee} />
    </>
  );
}

export default EmployeesData;

EditableCell.defaultProps = {
  editing: false,
  dataIndex: '',
  title: '',
  inputType: '',
  record: {
    name: '',
    email: '',
  },
  index: [],
  children: [],
};

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  inputType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  record: PropTypes.object,
  index: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.any,
};

EmployeesData.propTypes = {
  clientInfo: PropTypes.bool.isRequired,
};
