import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import * as styles from './styles';

import api from '~/services/api';

function ShowLead() {
  const [lead, setLead] = useState([]);
  const [loading, setLoading] = useState(false);

  const { leadId } = useParams();

  useEffect(() => {
    async function loadLead() {
      const response = await api.get(`/leads/${leadId}`);
      const { data } = response;
      setLead(data);

      setLoading(true);
    }

    loadLead();
  }, [leadId]);

  return (
    <styles.Container className="card">
      {loading ? lead : 'Carregando...'}
    </styles.Container>
  );
}

export default ShowLead;
