import React from 'react';

// import { Container } from './styles';

function AccountStatement() {
  return (
    <div>
      <h1>Extrato</h1>
    </div>
  );
}

export default AccountStatement;
