import React from 'react';

import { Link } from 'react-router-dom';

import Categorys from './content';

import * as styles from './styles';

import logo from '~/assets/logo.svg';

function Sidebar() {
  return (
    <styles.Container>
      <header>
        <img src={logo} alt="Logo BiPlan" />
      </header>
      <nav>
        {Categorys().map((category) => (
          <ul key={category.title}>
            <li className="category"> {category.title}</li>
            {category.links.map((link) => (
              <>
                {link.dev === true ? (
                  <li>
                    <span>Em Manutenção</span>
                  </li>
                ) : (
                  <Link key={link.text} to={link.route}>
                    <li>
                      {link.icon}
                      <span>{link.text}</span>
                    </li>
                  </Link>
                )}
              </>
            ))}
          </ul>
        ))}
      </nav>
    </styles.Container>
  );
}

export default Sidebar;
