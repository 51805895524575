import React, { useState, useEffect } from 'react';

import InfoCompany from './InfoCompany';

import api from '~/services/api';

export default function Info() {
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadCompany() {
      const response = await api.get(`/companies`);
      const { data } = response;
      setCompany(data[0]);
      setLoading(true);
    }

    loadCompany();
  }, []);

  return <>{loading ? <InfoCompany responseCompanyId={company.id} /> : ''}</>;
}
