import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Card, Collapse } from 'antd';

import { updateProfileRequest } from '~/store/modules/user/actions';

const { Panel } = Collapse;

// import { Container } from './styles';

export default function ProfileInfo() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);

  function handleSubmit(data) {
    data.id = profile.id;
    dispatch(updateProfileRequest(data));
  }

  function onFinishFailed(errorInfo) {
    console.log('Failed:', errorInfo);
  }

  return (
    <>
      <Form
        name="basic"
        initialValues={{
          name: profile.name,
          email: profile.email,
        }}
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Card
          title="Informações Gerais"
          style={{ width: '100%' }}
          bordered={false}
        >
          <Form.Item
            label="Nome"
            name="name"
            style={{
              display: 'inline-block',
              width: '50%',
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="E-Mail"
            name="email"
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)',
              marginLeft: '8px',
            }}
          >
            <Input />
          </Form.Item>
        </Card>

        <Collapse>
          <Panel
            header="Alterar Senha"
            key="ChangePassword"
            style={{ marginBottom: '20px' }}
          >
            <Card title="Senha" style={{ width: '100%' }} bordered={false}>
              <Form.Item
                label="Senha Atual"
                name="oldPassword"
                style={{
                  display: 'inline-block',
                  width: '33% ',
                }}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Nova Senha"
                name="password"
                style={{
                  display: 'inline-block',
                  width: 'calc(33% - 8px)',
                  marginLeft: '8px',
                }}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirmar Nova Senha"
                name="confirmPassword"
                style={{
                  display: 'inline-block',
                  width: 'calc(33% - 8px)',
                  marginLeft: '8px',
                }}
              >
                <Input.Password />
              </Form.Item>
            </Card>
          </Panel>
        </Collapse>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Atualizar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
