import React, { useState, useEffect } from 'react';

import Table from './AntdTable/index';
import api from '~/services/api';

import * as styles from './styles';

export default function Clients() {
  const [clients, setClients] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    async function loadClients() {
      try {
        const response = await api.get(`/companies`);
        const { data } = response;

        setClients(data);
        setTableLoading(false);
      } catch (err) {
        setTableLoading(false);
      }
    }

    loadClients();
  }, []);

  return (
    <styles.Container>
      <Table title="Clientes" data={clients} loading={tableLoading} />
    </styles.Container>
  );
}
