import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Container = styled.div`
  margin-left: 240px;
  width: calc(100% - 240px);
`;

export const Content = styled.div`
  padding: 24px;
`;
