import React, { useEffect, useState } from 'react';
import { Table, Button, Space, Tooltip, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { DeleteOutlined, ProfileOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as styles from './styles';
import api from '~/services/api';

export default function AntdTable({ title, data, loading }) {
  const [resData, setResData] = useState([]);

  useEffect(() => {
    async function loadUsers() {
      setResData(data);
    }

    loadUsers();
  }, [data]);

  async function handleDelete(cliente, rowIndex) {
    try {
      await api.delete(`companies/${cliente.id}`);
      const dataDelete = [...resData];
      dataDelete.splice(rowIndex, 1);
      setResData([...dataDelete]);
      toast.success('Cliente deletado com sucesso!');
    } catch (error) {
      toast.error('Não foi possível deletar o cliente');
    }
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'E-mail',
      dataIndex: 'contact_email',
      key: 'contact_email',
    },
    {
      title: 'Contato',
      dataIndex: 'contact_phone',
      key: 'contact_phone',
    },
    {
      title: 'Companhia',
      dataIndex: 'fantasy_name',
      key: 'fantasy_name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, cliente, rowIndex) => (
        <Space size="middle">
          <Tooltip title="Ver Mais Informações">
            <Link to={`/clientes/${cliente.id}`}>
              <Button shape="circle" icon={<ProfileOutlined />} />
            </Link>
          </Tooltip>
          <Popconfirm
            title="Deseja mesmo Deletar?"
            onConfirm={() => handleDelete(cliente, rowIndex)}
          >
            <Tooltip title="Deletar">
              <Button type="" shape="circle" icon={<DeleteOutlined />} danger />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const orderedColumns = columns.filter((col) => col.dataIndex !== 'id');

  return (
    <styles.Container>
      <Table
        title={() => title}
        columns={orderedColumns}
        dataSource={resData}
        loading={loading}
        rowKey="id"
        pagination={{
          total: resData.length,
          pageSize: resData.length,
          hideOnSinglePage: true,
        }}
      />
    </styles.Container>
  );
}

AntdTable.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
