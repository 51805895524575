import React from 'react';

// import { Container } from './styles';

function Plans() {
  return (
    <div>
      <h1>Planos</h1>
    </div>
  );
}

export default Plans;
