/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from '~/services/api';

function Slug({ client, handleChange }) {
  const [urlDisable, setUrlDisable] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [form] = Form.useForm();
  const validateMessages = {
    types: {
      email: 'Insira um email válido!',
      number: 'Insira um número válido!',
    },
  };

  useEffect(() => {
    async function VerifySlug() {
      if (client.slug !== null) {
        setUrlDisable(true);
      }
    }

    VerifySlug();
  }, [client]);

  async function onFinishUrl(formData) {
    formData.slug = inputValue;
    const response = await api
      .put(`/companies/${client.id}`, formData)
      .then(
        handleChange(),
        toast.success('Informações da empresa atualizadas!')
      )
      .catch((error) => {
        if (
          error.response.data.error ===
          'You do not have permission to update this company'
        ) {
          return toast.error(`Não foi possível atualizar os dados.\n
          Você não tem permissão para atualizar esta empresa.`);
        }
        if (error.response.data.error === 'Company was not found') {
          return toast.error(`Não foi possível atualizar os dados.\n
          Empresa não encontrada.`);
        }
        if (
          error.response.data.error ===
          '"Contact email alredy in use", "Charge email alredy in use", "Document alredy in use", "IE alredy in use", "IM alredy in use"'
        ) {
          return toast.error(`Não foi possível atualizar os dados.\n
          Dados sendo utilizados em outra empresa.`);
        }
        return toast.error('Não foi possível atualizar o dado de URL.');
      });

    if (response) {
      toast.success('Url atualizada com sucesso!');
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={client}
      validateMessages={validateMessages}
    >
      <Card
        title="Informações do Ambiente"
        style={{ width: '100%' }}
        bordered={false}
      >
        {client.slug === null ? (
          ''
        ) : (
          <Form.Item>
            <Button
              type="primary"
              href={`https://${client.slug}.biplan.com.br`}
              target="_blank"
            >
              Acessar ambiente
            </Button>
          </Form.Item>
        )}
        <Form.Item
          label="URL da plataforma:"
          name="slug"
          style={{ display: 'inline-block', width: '50%' }}
          rules={[
            { required: true, message: 'Escolha um nome válido para URL!' },
          ]}
        >
          <Input
            disabled={urlDisable}
            onBlur={(e) => setInputValue(e.target.value)}
            addonBefore="https://"
            addonAfter=".biplan.com.br"
            placeholder="Url desejada para o ambiente"
          />
        </Form.Item>
        <Form.Item>
          <Popconfirm
            title={`Tem certeza que deseja essa a URL: "https//${inputValue}.biplan.com.br"? Isso não pode ser alterado depois.`}
            okText="Sim"
            onConfirm={onFinishUrl}
            cancelText="Não"
          >
            <Button disabled={urlDisable} type="primary" htmlType="submit">
              Salvar
            </Button>
          </Popconfirm>
        </Form.Item>{' '}
      </Card>
    </Form>
  );
}

export default Slug;

Slug.propTypes = {
  client: PropTypes.element.isRequired,
  handleChange: PropTypes.func.isRequired,
};
