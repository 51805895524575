/* eslint-disable no-nested-ternary */
import React from 'react';
import { Alert } from 'antd';
import PropTypes from 'prop-types';
import * as styles from './styles';

export default function StatusAlert({ data }) {
  return (
    <styles.Container>
      {data.status === 'Instanciar' && data.slug === null ? (
        <Alert
          message="Para a conclusão do instanciamento do seu ambiente por gentileza preencha o campo URL em 'Dados da empresa' e salve. Para mais informações contate o suporte."
          type="warning"
          showIcon
        />
      ) : data.status === 'Instanciar' ? (
        <Alert
          message="Seu Ambiente foi configurado e será instanciado em breve. Para mais informações contate o suporte."
          type="warning"
          showIcon
          closable
        />
      ) : data.status === 'Desativado' ? (
        <Alert
          message="Seu Ambiente se encontra atualmente desativado. Para mais informações contate o suporte."
          type="error"
          showIcon
          closable
        />
      ) : (
        ''
      )}
    </styles.Container>
  );
}

StatusAlert.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
