import React, { useState } from 'react';
import { Card, Form, Input } from 'antd';
import Cards from 'react-credit-cards';
import MaskedInput from 'antd-mask-input';
import 'react-credit-cards/es/styles-compiled.css';

// import { Container } from './styles';

function Payment() {
  const [form] = Form.useForm();

  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [focus, setFocus] = useState('');

  return (
    <Card title="Pagamento" style={{ width: '100%' }} bordered={false}>
      <Cards
        cvc={cvc}
        expiry={expiry}
        focused={focus}
        name={name}
        number={number}
        locale={{ valid: 'Válido até' }}
        placeholders={{ name: 'NOME DO CARTÃO' }}
      />
      <Form form={form} layout="vertical">
        <Form.Item
          label="Numero do Cartão:"
          style={{ display: 'inline-block', width: '50%' }}
        >
          <MaskedInput
            name="number"
            onFocus={(e) => setFocus(e.target.name)}
            onChange={(e) => setNumber(e.target.value)}
            mask="1111 1111 1111 1111"
            placeholder="1111 1111 1111 1111"
          />
        </Form.Item>

        <Form.Item
          label="Nome:"
          style={{ display: 'inline-block', width: '50%' }}
        >
          <Input
            name="name"
            style={{ textTransform: 'uppercase' }}
            onFocus={(e) => setFocus(e.target.name)}
            onChange={(e) => setName(e.target.value)}
            placeholder="John Doe..."
          />
        </Form.Item>

        <Form.Item
          label="Validade:"
          style={{ display: 'inline-block', width: '50%' }}
        >
          <MaskedInput
            name="expiry"
            onFocus={(e) => setFocus(e.target.name)}
            onChange={(e) => setExpiry(e.target.value)}
            mask="11/11"
            placeholder="MM/AA"
          />
        </Form.Item>

        <Form.Item
          label="CVC:"
          style={{ display: 'inline-block', width: '50%' }}
        >
          <MaskedInput
            name="cvc"
            onFocus={(e) => setFocus(e.target.name)}
            onChange={(e) => setCvc(e.target.value)}
            placeholder="111"
            mask="111"
          />
        </Form.Item>
      </Form>
      <h1>Pagamento</h1>
    </Card>
  );
}

export default Payment;
