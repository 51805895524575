import React, { useState } from 'react';
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Radio,
  InputNumber,
} from 'antd';
import cep from 'cep-promise';
import { toast } from 'react-toastify';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';

import MaskedInput from 'antd-mask-input';

import PropTypes from 'prop-types';
import api from '~/services/api';

function NewAddress({ data, handleChange }) {
  const company = data;

  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  async function searchCep(cepInfo) {
    try {
      setLoading(true);
      const response = await cep(cepInfo);
      setLoading(false);
      form.setFieldsValue({
        state: response.state,
        city: response.city,
        neighborhood: response.neighborhood,
        street: response.street,
      });
    } catch (error) {
      toast.error('CEP não encontrado.');
      setLoading(false);
    }
  }

  function showDrawer() {
    setVisible(true);
  }

  async function onFinish(values) {
    await api
      .post(`/address`, {
        billing_address: !(
          values.billing_address === undefined ||
          values.billing_address === false
        ),
        zip_code: values.zip_code,
        state: values.state,
        city: values.city,
        neighborhood: values.neighborhood,
        street: values.street,
        number_street: values.number_street,
        company_id: company,
      })
      .then(() => {
        toast.success('Endereço criado com sucesso!');
        handleChange();
      })
      .catch((error) => {
        if (error.response.data.error === 'Validation fails') {
          return toast.error(`Não foi possível cadastrar um novo endereço.\n
          Falha na autenticação.`);
        }
        return toast.error(
          'Não foi possível cadastrar um novo endereço. Verifique se os campos foram preenchidos corretamente.'
        );
      });
  }

  function onClose() {
    setVisible(false);
  }

  return (
    <>
      <Button type="primary" onClick={showDrawer} style={{ marginTop: '20px' }}>
        <PlusOutlined /> Novo endereço
      </Button>
      <Drawer
        title="Adicionar novo endereço"
        width={720}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Endereço de:"
                name="billing_address"
                tooltip={{
                  title: 'Defina a forma de contato',
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Radio.Group defaultValue={false}>
                  <Radio.Button value>Cobrança</Radio.Button>
                  <Radio.Button value={false}>Contato</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="CEP"
                name="zip_code"
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <MaskedInput
                  mask="11111-111"
                  onBlur={(e) => searchCep(e.target.value)}
                  placeholder="Ex.: 12000-000"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Estado"
                name="state"
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <Input disabled={loading} placeholder="Ex.: SP" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Cidade:"
                name="city"
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <Input
                  disabled={loading}
                  placeholder="Ex.: São José dos Campos"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Bairro:"
                name="neighborhood"
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <Input disabled={loading} placeholder="Ex.: Vila Tatetuba" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item
                name="street"
                label="Endereço:"
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <Input
                  disabled={loading}
                  placeholder="Ex.: Rua Dona Lúcia Telles"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="number_street"
                label="Número:"
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <InputNumber placeholder="Ex.: 107" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Criar
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default NewAddress;

NewAddress.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
};
