import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;

  width: 240px;
  height: 100%;
  position: fixed;

  header {
    background: #fff;
    color: #fff;
    height: 64px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  nav {
    padding: 20px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;

    a {
      color: rgba(0, 0, 0, 0.8);
      cursor: pointer;

      :hover {
        color: #1890ff;
        background: #e6f7ff;
      }
    }

    li {
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 4px 10px;

      &.category {
        padding: 10px 0 5px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.5px;
        font-size: 12px;
      }

      svg {
      }

      span {
        margin-left: 10px;
      }
    }
  }
`;
