import React, { useState } from 'react';
import { Form, Input, InputNumber, Button, Select, Checkbox, Card } from 'antd';

import { toast } from 'react-toastify';

import api from '~/services/api';

function IncomingInfo({ handleChange, data }) {
  const [form] = Form.useForm();
  const mail = data;

  const [sslTls, setSslTls] = useState(mail ? mail.ssl_tls : false);

  function handleCheckbox() {
    setSslTls(!sslTls);
  }

  async function onFinish(formData) {
    try {
      await api.put(`/emailserver/${mail.id}`, {
        server_type: formData.server_type,
        server_name: formData.server_name,
        server_port: formData.server_port,
        ssl_tls: sslTls,
        username: formData.username,
        password: formData.password,
        incoming: mail.incoming,
        company_id: mail.company_id,
      });
      handleChange();
      toast.success('Informações de entrada atualizadas com sucesso!');
    } catch (error) {
      if (
        error.response.data.error ===
        'You do not have permission to see this email server'
      ) {
        return toast.error(`Erro ao atualizar informações de entrada.\n
    Você não tem permissão para alterar informações de entrada.`);
      }
      if (error.response.data.error === 'Email Server not found') {
        return toast.error(`Erro ao atualizar informações entrada.\n
    Informação de entrada não encontrada.`);
      }
      return toast.error('Erro ao atualizar informações de entrada');
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={mail}
      onFinish={onFinish}
    >
      <Card title="Servidor de entrada">
        <Card bordered={false}>
          <Form.Item label="Tipo de servidor" name="server_type">
            <Select>
              <Select.Option value="IMAP">IMAP</Select.Option>
              <Select.Option value="POP">POP</Select.Option>
            </Select>
          </Form.Item>
        </Card>

        <Card title="Informação do Servidor" bordered={false}>
          <Form.Item
            label="Nome do Servidor"
            name="server_name"
            style={{
              display: 'inline-block',
              width: '75%',
            }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Porta"
            name="server_port"
            style={{
              display: 'inline-block',
              width: 'calc(25% - 8px)',
              marginLeft: '8px',
            }}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            label="SSL/TLS"
            name="ssl_tls"
            style={{
              display: 'inline-block',
              width: '33%',
            }}
          >
            <Checkbox checked={sslTls} onChange={handleCheckbox} />
          </Form.Item>
        </Card>

        <Card title="Usuário para Login" bordered={false}>
          <Form.Item
            label="Nome do Usuário"
            name="username"
            style={{
              display: 'inline-block',
              width: '50%',
            }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Senha"
            name="password"
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)',
              marginLeft: '8px',
            }}
          >
            <Input.Password />
          </Form.Item>
        </Card>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </Card>
    </Form>
  );
}

export default IncomingInfo;
