/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { Form, Input, Modal, Button } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from '~/services/api';

function NewEmployee({ id, AddEmployee }) {
  const [visible, setVisible] = useState(false);
  const [confirmLoading] = useState(false);

  const [form] = Form.useForm();

  function showModal() {
    setVisible(true);
  }

  async function onFinish(formData) {
    formData.company_id = id;
    await api
      .post('/employees', formData)
      .then(toast.success('Funcionário criado com sucesso!'))
      .catch((error) => {
        if (error.response.data.error === 'Validation fails') {
          return toast.error(`Não foi possível criar novo funcionário.\n
    Falha na autenticação.`);
        }
        if (
          error.response.data.error ===
          'You do not have permission to create an employee'
        ) {
          return toast.error(`Não foi possível criar novo funcionário.\n
    Você não tem permissão para criar um novo funcionário.`);
        }
        if (error.response.data.error === 'Email already in use') {
          return toast.error(`Não foi possível criar novo funcionário.\n
    Email em uso.`);
        }
        return toast.error('Não foi possível criar novo funcionário.');
      });
  }

  function handleCancel() {
    setVisible(false);
    AddEmployee();
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        <PlusOutlined /> Novo funcionário
      </Button>
      <Modal
        form={form}
        title="Novo funcionário"
        visible={visible}
        footer={null}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Nome:" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default NewEmployee;

NewEmployee.propTypes = {
  id: PropTypes.string.isRequired,
  AddEmployee: PropTypes.func.isRequired,
};
