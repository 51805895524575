/* eslint-disable no-param-reassign */
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import api from '~/services/api';

function DigitalCertificate({ company, data }) {
  const digitalCertificate = data;
  const [form] = Form.useForm();
  const token = useSelector((state) => state.auth.token);
  const [certificateUploaded, setCertificateUploaded] = useState(null);

  async function onFinish(values) {
    const fileId = certificateUploaded;
    values.company_id = company.id;

    if (digitalCertificate) {
      await api
        .put(`/digitalcertificates/${digitalCertificate.id}`, {
          password: values.password,
          file_id: fileId,
          company_id: company.id,
        })
        .then(toast.success('Certificado digital atualizado com sucesso!'))
        .catch((error) => {
          if (
            error.response.data.error ===
            'You do not have permission to delete a Digital certificate'
          ) {
            return toast.error(`Não foi possível atualizar o certificado digital.\n
          Você não tem permissão para atualizar o certificado digital.`);
          }
          if (
            error.response.data.error === 'Digital Certificate does not found'
          ) {
            return toast.error(`Não foi possível atualizar o certificado digital.\n
          Certificado digital não encontrado.`);
          }
          return toast.error(
            'Não foi possível atualizar o certificado digital.'
          );
        });
    } else {
      await api
        .post(`/digitalcertificates`, {
          password: values.password,
          file_id: fileId,
          company_id: company.id,
        })
        .then(toast.success('Certificado digital adicionado com sucesso!'))
        .catch((error) => {
          if (error.response.data.error === 'Validation fails') {
            return toast.error(`Não foi possível adicionar o certificado digital.\n
        Erro na validação.`);
          }
          if (
            error.response.data.error ===
            'You do not have permission to create a Digital certificate'
          ) {
            return toast.error(`Não foi possível adicionar o certificado digital.\n
        Você não tem permissão para adicionar o certificado digital.`);
          }
          return toast.error(
            'Não foi possível adicionar o certificado digital.'
          );
        });
    }
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={digitalCertificate}
        onFinish={onFinish}
      >
        <Form.Item
          label="Certificado:"
          name="certificate"
          style={{
            display: 'inline-block',
            width: '50%',
          }}
        >
          {digitalCertificate && digitalCertificate.certificate ? (
            <>
              <Link
                to={{ pathname: digitalCertificate.certificate.url }}
                target="_blank"
              >
                Baixar arquivo já anexado
              </Link>
              <br />
            </>
          ) : (
            ''
          )}

          <br />
          <Upload
            name="file"
            listType="picture"
            action="https://api.portal.biplan.com.br/files"
            headers={{
              authorization: `Bearer ${token}`,
            }}
            onChange={(info) => {
              if (info.file.status === 'done') {
                setCertificateUploaded(info.file.response.id);
                message.success(`${info.file.name} file uploaded successfully`);
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
          >
            <Button icon={<UploadOutlined />}>
              {digitalCertificate ? 'Alterar arquivo' : 'Enviar'}
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Senha:"
          name="password"
          rules={[{ required: true, message: 'Insira senha do certificado' }]}
          style={{
            display: 'inline-block',
            width: '50%',
          }}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default memo(DigitalCertificate);

DigitalCertificate.propTypes = {
  company: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
