import React, { useState, useEffect } from 'react';

import { Card } from 'antd';
import EmployeesData from '~/components/EmployeesData';

import { Container } from './styles';

import api from '~/services/api';

export default function Employees() {
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadCompany() {
      const response = await api.get(`/companies`);
      const { data } = response;
      setCompany(data[0]);
      setLoading(true);
    }

    loadCompany();
  }, []);

  return (
    <>
      {loading ? (
        <Container>
          <Card
            title="Funcionários"
            style={{ width: '100%' }}
            bordered={false}
            loading={!loading}
          >
            <EmployeesData clientInfo={company.id} />
          </Card>
        </Container>
      ) : (
        ''
      )}
    </>
  );
}
