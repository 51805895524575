import React, { useState, useEffect, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { Card, Collapse } from 'antd';
import Client from '~/components/Client';
import Address from '~/components/Address';
import NewAddress from '~/components/NewAddress';
import EmployeesData from '~/components/EmployeesData';
import DigitalCertificate from '~/components/DigitalCertificate';

import { Container } from './styles';

import api from '~/services/api';

const { Panel } = Collapse;

export default function ShowLead() {
  const { clientId } = useParams();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState([true]);
  const [listAddress, setListAddress] = useState([]);

  const [refresh, setRefresh] = useState(false);

  const [certificate, setCertificate] = useState(null);
  const [loadingCertificate, setLoadingCertificate] = useState(true);

  const handleEditCompanyInfo = useCallback(() => {
    setRefresh(true);
  }, []);

  useEffect(() => {
    async function loadCertificate(companyId) {
      await api
        .get(`/digitalcertificates/${companyId}`)
        .then((response) => {
          setCertificate(response.data);
        })
        .catch(() => {
          setLoadingCertificate(false);
        });

      setLoadingCertificate(false);
    }

    async function loadCompany() {
      const response = await api.get(`/companies/${clientId}`);
      const { data } = response;
      setCompany(data);
      setLoading(false);

      loadCertificate(data.id);
    }

    loadCompany();
    setRefresh(false);
  }, [clientId, refresh]);

  useEffect(() => {
    async function loadAddress() {
      const response = await api.get(`/address/${clientId}`);
      const { data } = response;
      setListAddress(data);
    }

    loadAddress();
    setRefresh(false);
  }, [clientId, refresh]);

  return (
    <Container>
      <Collapse
        defaultActiveKey={['CompanyData']}
        style={{ width: '100%' }}
        accordion
      >
        <Panel header="Dados da Empresa" key="CompanyData">
          <Card style={{ width: '100%' }} bordered={false} loading={!company}>
            {!loading ? (
              <Client data={company} handleChange={handleEditCompanyInfo} />
            ) : (
              ''
            )}
          </Card>
        </Panel>

        <Panel header="Endereços cadastrados" key="ClientAddress">
          <Card
            style={{ width: '100%' }}
            bordered={false}
            loading={!listAddress}
          >
            {listAddress.map((address) => (
              <Address
                key={address.id}
                handleChange={handleEditCompanyInfo}
                data={address}
              />
            ))}
            <NewAddress data={clientId} handleChange={handleEditCompanyInfo} />
          </Card>
        </Panel>

        <Panel header="Funcionários" key="Employees">
          <Card style={{ width: '100%' }} bordered={false}>
            <EmployeesData clientInfo={clientId} />
          </Card>
        </Panel>

        <Panel header="Certificado Digital" key="DigitalCertificate">
          <Card
            style={{ width: '100%' }}
            bordered={false}
            loading={loadingCertificate}
          >
            {!loadingCertificate ? (
              <DigitalCertificate company={company} data={certificate} />
            ) : (
              ''
            )}
          </Card>
        </Panel>
      </Collapse>
    </Container>
  );
}
