/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Form, Input, InputNumber, Button, Card, Checkbox, Drawer } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { toast } from 'react-toastify';

import api from '~/services/api';

function OutgoingInfo({ handleChange, data, id }) {
  const [form] = Form.useForm();

  const [sslTls, setSslTls] = useState(false);
  const [visible, setVisible] = useState(false);

  function handleCheckbox() {
    setSslTls(!sslTls);
  }

  const [disable, setDisable] = useState(
    data.length === 0
      ? false
      : data[0].server_type === 'SMTP'
      ? true
      : !data[1]
      ? false
      : data[1].server_type === 'SMTP'
  );

  async function onFinish(formData) {
    try {
      await api.post(`/emailserver`, {
        server_type: 'SMTP',
        server_name: formData.server_name,
        server_port: formData.server_port,
        ssl_tls: sslTls,
        username: formData.username,
        password: formData.password,
        incoming: false,
        company_id: id,
      });
      handleChange();
      toast.success('Informações de saída criadas com sucesso!');
      setVisible(false);
      setDisable(true);
    } catch (error) {
      toast.error('Erro ao criar informações saída');
    }
  }

  function showDrawer() {
    setVisible(true);
  }

  function onClose() {
    setVisible(false);
  }

  return (
    <>
      <Button
        disabled={disable}
        type="primary"
        onClick={showDrawer}
        style={{ margin: '20px' }}
      >
        <PlusOutlined /> Adicionar Servidor de saída
      </Button>
      <Drawer
        title="Servidor de saída"
        width={720}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Card title="Informações de Conexão" bordered={false}>
            <Form.Item
              label="Servidor SMTP"
              name="server_name"
              style={{
                display: 'inline-block',
                width: '75%',
              }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Porta SMTP"
              name="server_port"
              style={{
                display: 'inline-block',
                width: 'calc(25% - 8px)',
                marginLeft: '8px',
              }}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="SSL/TLS"
              name="ssl_tls"
              style={{
                display: 'inline-block',
                width: '33%',
              }}
            >
              <Checkbox checked={sslTls} onChange={handleCheckbox} />
            </Form.Item>
          </Card>

          <Card title="Autenticação e Segurança" bordered={false}>
            <Form.Item
              label="Nome do usuário"
              name="username"
              style={{
                display: 'inline-block',
                width: '50%',
              }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Senha"
              name="password"
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                marginLeft: '8px',
              }}
            >
              <Input.Password />
            </Form.Item>
          </Card>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Criar
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default OutgoingInfo;
