import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '~/pages/SignIn';

import Profile from '~/pages/Profile';
import Info from '~/pages/Company/Info';
import Employees from '~/pages/Company/Employees';
import Payment from '~/pages/Company/Payment';
import AccountStatement from '~/pages/Company/AccountStatement';
import Storage from '~/pages/Company/Storage';
import Plans from '~/pages/Company/Plans';
import AccountCancel from '~/pages/Company/AccountCancel';
import Leads from '~/pages/Leads';
import ShowLead from '~/pages/Leads/ShowLead';
import Clients from '~/pages/Clients';
import ListClient from '~/pages/Clients/ShowClient';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/empresa/info" component={Info} isPrivate />
      <Route path="/empresa/funcionarios" component={Employees} isPrivate />
      <Route path="/empresa/pagamento" component={Payment} isPrivate />
      <Route path="/empresa/extrato" component={AccountStatement} isPrivate />
      <Route path="/empresa/armazenamento" component={Storage} isPrivate />
      <Route path="/empresa/planos" component={Plans} isPrivate />
      <Route path="/empresa/cancelamento" component={AccountCancel} isPrivate />

      <Route path="/leads" exact component={Leads} isClassified isPrivate />
      <Route
        path="/leads/:leadId"
        exact
        component={ShowLead}
        isClassified
        isPrivate
      />

      <Route
        path="/clientes"
        exact
        component={Clients}
        isClassified
        isPrivate
      />
      <Route
        path="/clientes/:clientId"
        exact
        component={ListClient}
        isPrivate
      />
      <Route path="/" exact component={SignIn} />
    </Switch>
  );
}
